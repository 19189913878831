<template>
    <div class="d-flex align-items-center">
        <div class="d-inline-flex m-auto text-center">
            Please wait...
        </div>
    </div>
</template>
<script>
    import {setRefreshTokenState} from "../helpers/handleRefreshToken";
    import moment from 'moment'
    import {RefreshStateModel} from "../models/state/RefreshStateModel";

    export default {
        name: "ProcessTokens",
        mounted() {
            let {
                access_token,
                refresh_token,
                expires_in
            } = this.$route.query;

            setRefreshTokenState(
                new RefreshStateModel(access_token, refresh_token, moment().unix(), expires_in)
            );

            this.$store.dispatch('User/getUser');

            this.$router.push({
                name: 'Portal.Dashboard'
            })
        }
    }
</script>

<style scoped>

</style>